<template>
  <div class="loading" v-show="show">
    <div class="loading-mask"></div>
    <div class="loading-toast">
      <i class="loading-icon"></i>
      <p class="loading-content">{{text}}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'loading',
  props: {
    show: Boolean,
    text: String
  }
}
</script>
<style lang="less" scoped>
.loading {
  .loading-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  .loading-toast {
    position: fixed;
    z-index: 5000;
    width: 2.4rem;
    min-height: 2.4rem;
    top: 3.6rem;
    left: 50%;
    margin-left: -1.2rem;
    background: rgba(17,17,17,.7);
    text-align: center;
    border-radius: 5px;
    color: #fff;
    .loading-icon {
      display: inline-block;
      // margin: .64rem 0 0;
      width: .76rem;
      height: .76rem;
      vertical-align: baseline;
      animation: loading 1s steps(12, end) infinite;
      // background: transparent url('../assets/data_image_svg+xml;….svg') no-repeat;
      background-size: 100%;
    }
    .loading-content {
      margin: 0 0 .32rem;
      font-size: .28rem;
    }
  }
}
@keyframes loading {
  0% {
    transform: rotate3d(0,0,1,0deg);
  }
  100% {
    transform: rotate3d(0,0,1,360deg);
  }
}
</style>